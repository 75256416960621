import { Text } from "@fluentui/react"

const StripItem = ({ title, subtitle }: any) => (
    <div className="flex-1 px-6 py-3 hover:bg-gray-200 transition-all">
        <div className="flex flex-col">
            <Text variant={'smallPlus'} className={'font-[Poppins]'}>{title}</Text>
            <Text variant={'mediumPlus'} className={'font-[Poppins]'}>{subtitle}</Text>
        </div>
    </div>
)

export default StripItem