
import { AppBridgeProvider } from "@dladio/app-bridge";
import { CoreUIProvider } from "@dladio/core-ui";
import Template1 from "./examples/Template1/Template1";
import BPStrip from "./strips/BPStrip/BPStrip";


function App() {
  return (
    <AppBridgeProvider apiKey="bp-strips">
      <CoreUIProvider>
        <BPStrip />
      </CoreUIProvider >
    </AppBridgeProvider>
  );
}

export default App;
