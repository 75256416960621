import { Stack } from "@dladio/core-ui"

const Strip = ({ children }: any) => {
    return (
        <>
            <div className="p-2 bg-[#f0f0f0]">
                <Stack className={'bg-white h-[70px] divide-x divide-gray-200 rounded-md shadow-md'}>
                    {children}
                </Stack>
            </div>
        </>
    )
}

export default Strip