import { useAppBridgeSelector } from "@dladio/app-bridge"
import { SERVICE } from "@dladio/service"
import _ from "lodash"
import Strip from "../../components/Strip/Strip"
import StripItem from "../../components/StripItem/StripItem"
import StripItemWrapper from "../../components/StripItemWrapper/StripItemWrapper"

const BPStrip = () => {

    const { model } = useAppBridgeSelector()

    return (
        <>
            <Strip>
                <StripItemWrapper title={"Last Order Date"} body={{ name: "d2ee675a7c", id: _.get(model, 'model.CardCode') }} method={SERVICE.EXEC_QUERY_METHOD} />
                <StripItemWrapper title={"Open Activity Count"} body={{ name: "a5eea359cc", id: _.get(model, 'model.CardCode') }} method={SERVICE.EXEC_QUERY_METHOD} />
                <StripItem title={"Balance Due"} subtitle={_.get(model, 'model.BalanceDue')} />
                <StripItemWrapper title={"Over Due"} body={{ name: "dc04f39916", id: _.get(model, 'model.CardCode') }} method={SERVICE.EXEC_QUERY_METHOD} />
                <StripItemWrapper title={"Open Cases"} body={{ name: "ccf31c3e6d", id: _.get(model, 'model.CardCode') }} method={SERVICE.EXEC_QUERY_METHOD} />
                <StripItemWrapper title={"Average Days To Pay"} body={{ name: "65f57e83b4", id: _.get(model, 'model.CardCode') }} method={SERVICE.EXEC_QUERY_METHOD} />
            </Strip>
        </>
    )
}



export default BPStrip