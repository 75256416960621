import { useDerive } from "@dladio/service"
import StripItem from "../StripItem/StripItem"

interface IProps {
    title: string
    body: any
    method: string
    type?: string
    path?: string
}

const StripItemWrapper = ({ type = "exec", method, path = "message.value", body = {}, title }: IProps) => {
    const { value } = useDerive(type, {
        options: {
            method,
            body
        },
        path
    })

    return (<StripItem title={title} subtitle={value ?? ''} />)

}

export default StripItemWrapper